<template>
  <b-card>
    <g-table
      ref="Row-table"
      :items="items"
      :columns="tableColumns"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: true , permission: 'addRows'}"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          openModal({ englishName: '' });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('preview')"
            v-permission="'editRows'"
            data-action-type="preview"
            variant="custom"
            class="btn-icon"
            size="sm"
            @click="openModal(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-prev-icon`"
              icon="EyeIcon"
              class="mx-1 clickable"
              :hidden="true"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission="'editRows'"
            data-action-type="edit"
            variant="custom"
            class="btn-icon"
            size="sm"
            @click="openModal(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
              class="mx-1 clickable"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteRows'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
    <b-sidebar
      id="sidebar-edit"
      ref="sidebar"
      no-close-on-backdrop
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      left
      @hidden="
        () => {
          selectedItem = {};
        }
      "
    >
      <template #default>
        <div
          class="d-flex justify-content-between bg-primary align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0 text-white">
            {{ selectedItem.id > 0 ? $t("Edit Row") : $t("New Row") }}
          </h5>
        </div>
        <b-card>
          <g-form @submit="save">
            <b-row>
              <b-col
                v-if="selectedItem.id > 0"
                md="12"
              >
                <label
                  style="font-size: 14px; margin-bottom: 7px"
                  for="code"
                >
                  {{ $t("code") }}
                </label>
                <g-field
                  id="code"
                  :value.sync="selectedItem.code"
                  type="number"
                  name="code"
                  disabled
                />
              </b-col>

              <b-col md="12">
                <!-- arabicName  -->
                <label
                  style="font-size: 14px; margin-bottom: 7px"
                  for="arabicName"
                >
                  {{ $t("arabicName") }}
                </label>
                <g-field
                  id="arabicName"
                  :value.sync="selectedItem.arabicName"
                  name="arabicName"
                  rules="required"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <label
                  style="font-size: 14px; margin-bottom: 7px"
                  for="englishName"
                >
                  {{ $t("englishName") }}
                </label>
                <g-field
                  id="englishName"
                  ref="englishName"
                  :value.sync="selectedItem.englishName"
                  name="englishName"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <g-field
                    v-model="selectedItem.stageId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    field="select"
                    name="stages"
                    rules="required"
                    :options="stages"
                    :label="isRight ? 'arabicName' : 'englishName'"
                    label-text="stage"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <label
                    style="font-size: 14px; margin-bottom: 7px"
                    for="customer"
                  >
                    {{ $t("notes") }}
                  </label>
                  <b-form-textarea
                    id="textarea"
                    v-model="selectedItem.notes"
                    label="Notes"
                    rows="3"
                    max-rows="6"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <h5> {{ $t('specialForStudentSucess') }} </h5>
                <span style="font-size: 12px; color: darkgray; letter-spacing: 1.1px;"> {{ $t('nextLevelHintMessage', {level: $t('row')}) }} </span>
                <b-form-group class="mt-1">
                  <g-field
                    field="select"
                    label="displayName"
                    label-text="nextRow"
                    name="nextRow"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    :value.sync="selectedItem.nextRow"
                    :options="items"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                class="d-flex justify-content-end"
              >
                <b-button
                  v-permission="$route.meta.permission"
                  class="mx-1"
                  type="submit"
                  variant="primary"
                  data-action-type="save"
                >
                  {{ $t("save") }}
                </b-button>
                <b-button
                  class="mx-1"
                  variant="secondary"
                  @click="closeModal"
                >
                  {{ $t("Close") }}
                </b-button>
              </b-col>
            </b-row>
          </g-form>
        </b-card>
      </template>
    </b-sidebar>
  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';

export default {
  components: {
    GTable,
  },
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      perPage: 25,
      isTableBusy: false,
      totalRows: 0,
      filterOn: [],
      filter: {
        orderClause: null,
      },
      items: [],
      selectedItem: {
        arabicName: '',
        englishName: '',
      },
      modal: false,
      Accounts: [],
      stages: [],
      itemsArray: [],
      totalVals: 0,
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('row'),
          sortable: true,
        },
        { key: this.isRight ? 'stageArabicName' : 'stageEnglisName', label: this.$t('stage'), sortable: true },
        { key: 'notes', label: this.$t('notes'), sortable: true },
        { key: 'actions' },
      ];
    },
  },
  mounted() {
    this.getSatges();
    this.getItems();
  },
  methods: {
    getItems() {
      this.get({ url: 'Rows' }).then((data) => {
        this.items = data;
        this.items.forEach(element => {
          element.displayName = element.arabicName.concat(' - ', element.stageArabicName)
        });
      })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getSatges() {
      this.get({ url: 'stages' }).then((data) => {
        this.stages = data;
      });
    },
    save() {
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'Rows',
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          this.closeModal();
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.getItems();
        });
      } else {
        this.create({ url: 'Rows', data: this.selectedItem }).then(() => {
          this.closeModal();
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.getItems();
        });
      }
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'Rows', id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getItems();
          });
        }
      );
    },
    closeModal() {
      this.$refs.sidebar.hide();
    },
    openModal(item) {
      this.selectedItem = { ...item };
      if (item.id) {
        this.get({ url: 'Rows', id: item.id }).then((data) => {
          this.selectedItem = data;
        });
      }
      this.$root.$emit('bv::toggle::collapse', 'sidebar-edit')
    },
  },
};
</script>

<style></style>
